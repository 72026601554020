<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <section class="card-student">
          <div class="wrapper-heading">
            <h2 class="heading-card-student">Admin's Profile</h2>
          </div>
          <section class="card-student-body">
            <loading-panel v-if="loading.getDetail" />
            <validation-observer v-else v-slot="{ handleSubmit }" ref="formValidator">
              <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <div class="wrapper-photo">
                  <div class="photo-student">
                    <img
                      v-if="previewCache"
                      class="photo"
                      :src="`${previewCache}?${new Date().getTime()}`"
                      :alt="account.fullname"
                    />
                    <img v-else class="photo" :src="preview" :alt="account.fullname" />
                  </div>
                  <!-- <base-button size="sm" outline type="primary" class="button-delete">
                    <label class="label-upload">
                      <span>Change Image</span>
                      <input
                        id="inputFile"
                        class="file-upload"
                        type="file"
                        accept="image/*"
                        @change="previewImage"
                        ref="imageFile"
                      />
                    </label>
                  </base-button>-->
                  <label class="label-upload">
                    <div
                      class="btn base-button btn-outline-primary btn-sm button-delete"
                    >Change Image</div>
                    <input
                      id="inputFile"
                      class="file-upload"
                      type="file"
                      accept="image/*"
                      @change="previewImage"
                      ref="imageFile"
                    />
                  </label>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Fullname"
                      class="mb-3"
                      name="Name"
                      placeholder="-"
                      :rules="{ required: true }"
                      v-model="account.name"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="NPA IDI"
                      class="mb-3"
                      name="NPA IDI"
                      placeholder="-"
                      v-model="account.npaidi"
                      :rules="{ required: true }"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Gender"
                      class="mb-3"
                      name="Gender"
                      :rules="{ required: true }"
                    >
                      <select v-model="account.gender.value" class="form-control">
                        <option
                          v-for="gender in account.gender.list"
                          :key="gender.name"
                          :value="gender.name"
                        >{{ gender.name }}</option>
                      </select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Telephone Number"
                      class="mb-3"
                      name="Telephone"
                      placeholder="-"
                      type="number"
                      :rules="{ required: true }"
                      v-model="account.nomor_tlp"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Profession"
                      class="mb-3"
                      name="Profession"
                      placeholder="-"
                      :rules="{ required: true }"
                      v-model="account.profesi"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Email"
                      class="mb-3"
                      name="Email"
                      placeholder="-"
                      type="email"
                      :rules="{ required: true, email: true }"
                      v-model="account.email"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Institute"
                      class="mb-3"
                      name="Institute"
                      placeholder="-"
                      :rules="{ required: true }"
                      v-model="account.institute"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input label="Bank" class="mb-3" name="Bank" :rules="{ required: true }">
                      <select class="form-control" v-model="account.bank.value">
                        <option
                          v-for="bank in account.bank.list"
                          :key="bank.name"
                          :value="bank.code"
                        >{{ bank.name }}</option>
                      </select>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Account Number"
                      class="mb-3"
                      name="Account Number"
                      placeholder="-"
                      type="number"
                      :rules="{ required: true }"
                      v-model="account.account_number"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <base-input label="Old Password" class="mb-3" name="Old Password">
                      <div class="input-group">
                        <input
                          :type="isPassword.oldPass ? 'password' : 'text'"
                          v-model="account.password"
                          class="form-control"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-primary"
                            type="button"
                            @click="changeSeePassword(1)"
                          >
                            <svg class="icon-password">
                              <use v-if="isPassword.oldPass" href="#type-password" />
                              <use v-else href="#type-nopassword" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </base-input>
                  </div>
                  <div class="col-md-4">
                    <validation-provider
                      name="new-password"
                      rules="password:@confirm"
                      v-slot="{ errors }"
                    >
                      <base-input label="New Password" class="mb-3" name="New Password">
                        <div class="input-group">
                          <input
                            :type="isPassword.NewPassOne ? 'password' : 'text'"
                            v-model="account.new_password.firstValue"
                            class="form-control"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-primary"
                              type="button"
                              @click="changeSeePassword(2)"
                            >
                              <svg class="icon-password">
                                <use v-if="isPassword.NewPassOne" href="#type-password" />
                                <use v-else href="#type-nopassword" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </base-input>
                      <span class="invalid-feedback" style="display: block;">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <validation-provider name="confirm" v-slot="{ errors }">
                      <base-input
                        label="Retype New Password"
                        class="mb-3"
                        name="Retype New Password"
                      >
                        <div class="input-group">
                          <input
                            :type="isPassword.NewPassTwo ? 'password' : 'text'"
                            v-model="account.new_password.secondValue"
                            class="form-control"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-primary"
                              type="button"
                              @click="changeSeePassword(3)"
                            >
                              <svg class="icon-password">
                                <use v-if="isPassword.NewPassTwo" href="#type-password" />
                                <use v-else href="#type-nopassword" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </base-input>
                      <span class="invalid-feedback" style="display: block;">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <div class="text-right">
                  <base-button outline type="primary" class="my-4" @click="handleCancel">Cancel</base-button>
                  <base-button
                    type="edit-vr"
                    native-type="submit"
                    class="my-4"
                    :disabled="changesConfirmation"
                  >Save Changes</base-button>
                </div>
              </form>
            </validation-observer>
          </section>
        </section>
      </div>
    </div>
    <modal :show.sync="loading.onSubmit" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
    <modal :show.sync="showModalSaveChanges" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Edit Admin’s Profile</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="changes">save these change?</span>
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button
            outline
            type="primary"
            class="my-4"
            @click="showModalSaveChanges = false"
          >Cancel</base-button>
          <base-button type="edit-vr" class="my-4" @click="handleSaveChanges">Save Changes</base-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import { mapGetters, mapActions } from 'vuex'
import { getAdminById, updateAdmin } from '@/services/admin'
import { getListBank } from '@/services/trainer'
import { extend } from 'vee-validate';
import LoadingPanel from '@/components/LoadingPanel.vue';

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});
export default {
  name: 'Setting',
  components: {
    DashboardNavbar,
    LoadingPanel
  },
  data() {
    return {
      routes: [
        {
          name: "setting",
          alias: "SETTING"
        },
      ],
      account: {
        image: "",
        name: '',
        npaidi: '',
        gender:
        {
          value: '',
          list:
            [
              { name: 'Laki-laki', },
              { name: 'Perempuan', },
            ]
        },
        nomor_tlp: '',
        profesi: '',
        email: '',
        institute: '',
        bank: {
          value: '',
          list: []
        },
        account_number: '',
        password: '',
        new_password: {
          firstValue: '',
          secondValue: '',
        }
      },
      isPassword: {
        oldPass: true,
        NewPassOne: true,
        NewPassTwo: true,
      },
      preview: "/img/default_picture.png",
      image: null,
      previewCache: null,
      loading: {
        getDetail: false,
        onSubmit: false
      },
      showModalSaveChanges: false,
    }
  },
  methods: {
    ...mapActions('auth', ['updateProfil']),
    changeSeePassword(value) {
      if (value === 1) {
        this.isPassword.oldPass = !this.isPassword.oldPass
      } else if (value === 2) {
        this.isPassword.NewPassOne = !this.isPassword.NewPassOne
      } else if (value === 3) {
        this.isPassword.NewPassTwo = !this.isPassword.NewPassTwo
      }
    },
    onSubmit() {
      this.showModalSaveChanges = true
    },
    async handleSaveChanges() {
      this.showModalSaveChanges = false
      try {
        const form = new FormData();
        form.append('id', this.getInfoAuth.id);
        form.append('name', this.account.name);
        form.append('email', this.account.email);
        form.append('password', this.account.password);
        form.append('new_password', this.account.new_password.secondValue);
        form.append('photo', this.image);
        form.append('npaidi', this.account.npaidi);
        form.append('gender', this.account.gender.value);
        form.append('nomor_tlp', this.account.nomor_tlp);
        form.append('profesi', this.account.profesi);
        form.append('institute', this.account.institute);
        form.append('bank', this.account.bank.value);
        form.append('account_number', this.account.account_number);
        this.loading.onSubmit = true
        const { data } = await updateAdmin(form)
        this.updateProfil(data)
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Data Successfuly Changed"
        });
        this.loading.onSubmit = false
        setTimeout(() => {
          this.$router.replace({ name: 'Dashboard-Admin' })
        }, 500);
      } catch (error) {
        this.loading.onSubmit = false
        console.error(error)
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    handleCancel() {
      this.$router.push({ name: "Dashboard-Admin" })
    },
    previewImage() {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (types.includes(this.$refs.imageFile.files[0].type)) {
        if ((this.$refs.imageFile.files[0].size / 1024).toFixed(2) < 3000) {
          this.previewCache = null
          this.image = this.$refs.imageFile.files[0];
          this.preview = URL.createObjectURL(this.image);
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "File size exceeds the limit"
          });
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be png/jpg/jpeg"
        });
      }
    },
    async getInfoAdmin() {
      try {
        this.loading.getDetail = true
        const { data } = await getAdminById(this.getInfoAuth.id)
        const { data: ListBank } = await getListBank()
        this.loading.getDetail = false
        this.account.name = data.name
        this.account.npaidi = data.npaidi
        this.account.gender.value = data.gender
        this.account.nomor_tlp = data.nomor_tlp
        this.account.profesi = data.profesi
        this.account.email = data.email
        this.account.institute = data.institute
        this.account.bank.value = data.bank
        this.account.bank.list = ListBank
        this.account.account_number = data.account_number
        this.image = data.photo
        this.previewCache = data.photo
        this.updateProfil(data)
      } catch (error) {
        this.loading.getDetail = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"]),
    changesConfirmation() {
      if (this.account.name !== "" && this.account.email !== "") {
        return false
      } else if (this.account.name !== "" && this.account.email !== "" && this.account.password !== "" && this.account.new_password.firstValue !== "") {
        return false
      }
      return true
    },
  },
  mounted() {
    this.getInfoAdmin()
  }

}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.card-student-body {
  background-color: #fff;
  position: relative;
  padding: 20px;

  .wrapper-photo {
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;

    .photo-student {
      position: absolute;
      transform: translate(0, -50%) scale(1);
      height: 170px;
      width: 170px;
      border-radius: 100px;
      background: rgb(175, 174, 174);
      overflow: hidden;

      .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .button-delete {
      margin-top: 45px;
    }
  }
}
.label-upload {
  margin: 0;
  cursor: pointer;
  .file-upload {
    display: none;
  }
}
.icon-password {
  width: 20px;
  height: 20px;
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .changes {
    color: $orange-course;
  }
}
</style>